@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AgError {
  text-align: center;
  &__Bunny__Game{
    overflow: hidden;
    padding-bottom: 100px;
    margin: 0 auto;
    @include media('>=phone') { 
      width: 410px;
      height: 450px;
    }
    padding-bottom: 100px;
    @include media('>=desktop') { 
      width: 600px;
      height: 400px;
      padding-bottom: 60px;
    }
    
  }
}